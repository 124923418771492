.userpage {
  background: linear-gradient(#d2c4d2, #42b1e0);
}

.option1 {
  position: absolute;
  top: -10px;
  right: 3px;
  width: 40px;
  height: 50px;
}

.option2 {
  position: absolute;
  top: 2px;
  right: 3px;
  width: 28px;
  height: 30px;
  transition: 1s;
  cursor: pointer;
  padding-left: 5px;
  z-index: 1;
  border-bottom: 1px solid black;
}

.optiongroup {
  position: absolute;
  top: 50px;
  right: 5px;
  background: linear-gradient(#b473e0, #1e807e);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optiongroup > hr {
  width: 97%;
  height: 1px;
  margin-top: 5px;
  background-color: black;
  border: none;
}

.UserOp {
  width: 100%;
  height: 28px;
  margin-top: 3px;
}

.Signout {
  border: none;
  border-radius: 5px;
  background: linear-gradient(#a41adf, #31b8f2);
  color: white;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  width: 60px;
  height: 20px;
}

.Signout > p {
  white-space: nowrap;
  font-size: 10px;
}

.usernameroot {
  display: flex;
  justify-content: center;
  text-align: center;
}

.username {
  padding-bottom: 6px;

  font-size: 20px;
  font-style: italic;
  color: black;
  font-style: italic;
  margin-top: 5px;
}

.username1 {
  background: linear-gradient(#b042b0, #42b1e0);
  border-radius: 20px;
  padding-right: 5px;
  padding-left: 5px;
}

.testpic1 {
  width: 100%;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(#d2c4d2, #42b1e0);
  object-fit: cover;
}

.MiddleProfile {
  display: flex;
}

.Pselect > button {
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 10px;
  position: absolute;
  bottom: 205px;
  left: 100px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.Pselect > button:first-child {
  background: linear-gradient(#5ee7f6, #a046e9);
  color: #59ecf1;
}

.Pselect > button:nth-child(2) {
  background: linear-gradient(#f65ed8, #7ddde8);
  color: white;
  margin-left: 90px;
}

.current2 {
  width: 280px;
  margin-top: 20px;
  border-radius: 50%;
  border: 2px blueviolet solid;
}

.Profile3 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px blueviolet solid;
  margin-left: 2px;
  margin-top: 20px;
  background: linear-gradient(#121112, #42b1e0);
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  outline: 0;
}

.cropper-dashed {
  display: none;
}

.editpic {
  position: absolute;
}

.post2 {
  border: 0.5px rgb(81, 178, 211) solid;
  height: 200px;
  background: linear-gradient(#a9baf1, #d9eaf1);
}

.info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 30px;
}

.info > hr {
  width: 100px;
  height: 1px;
  background-color: lightblue;
  border: none;
}

.rankd {
  padding: 10px;
  width: 70px;
}

.rankn {
  margin-top: -30px;
  margin-left: 48px;
  color: lightskyblue;
}

.rankn1 {
  margin-top: -46px;
  margin-left: 52px;
  color: lightskyblue;
}
.pledge {
  margin-top: 30px;
  margin-bottom: 8px;
  width: 30px;
}

.Profile2 {
  margin-top: 5px;
  width: 40px;
}

.camera {
  width: 40px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.middle {
  margin-top: 30px;
  height: 2px;
  background-color: rgb(2, 59, 78);
  border: none;
}

.ClickOption {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid aqua;
}

.ConnectIcon {
  width: 210px;
}

.Clickbottomoption {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.changepic {
  display: none;
}

.cash {
  width: 35px;
  margin-top: 3px;
}

@media screen and (max-width: 281px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .current2 {
    width: 280px;
    margin-top: 20px;
    border-radius: 50%;
    border: 2px blueviolet solid;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 282px) and (max-width: 361px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .current2 {
    width: 280px;
    margin-top: 20px;
    border-radius: 50%;
    border: 2px blueviolet solid;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 362px) and (max-width: 376px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .current2 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px blueviolet solid;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 377px) and (max-width: 393px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .current2 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px blueviolet solid;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 394px) and (max-width: 416px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .current2 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px blueviolet solid;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 417px) and (max-width: 541px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 542px) and (max-width: 769px) {
  .Title__Help {
    width: 18px;
  }

  .Logo {
    width: 15px;
    margin-right: 80px;
  }

  .Line {
    opacity: 0;
  }

  .Sign-up {
    width: 30px;
    height: 30px;
    margin-right: -5px;
  }

  .Login {
    width: 45px;
    height: 45px;
    margin-right: -5px;
  }

  .Flag {
    width: 35px;
    height: 30px;
    margin-right: 200px;
  }
}

@media screen and (min-width: 767px) and (max-width: 818px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}

@media screen and (min-width: 819px) and (max-width: 821px) {
  .IntroLine {
    display: flex;
    margin-top: 8px;
  }

  .bothlocation {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-self: self-start;
    padding-top: 5px;
  }

  .location {
    width: 5px;
    margin-top: 8px;
  }
  .locationtext {
    font-size: 14px;
    color: gray;
  }

  .introinput {
    border: none;
    font-style: italic;
    background: transparent;
    text-align: center;
  }

  .introinput::placeholder {
    color: gray;
    border: none;
    outline: none;
    padding-top: 5.5px;
  }

  .introinput1 {
    border-left: 1px rgba(3, 48, 48, 0.523) solid;
    border-right: 1px rgba(3, 48, 48, 0.523) solid;
    border-bottom: none;
    border-top: none;
    outline: none;
    font-style: italic;
    background: transparent;
    text-align: center;
    padding-top: 7.8px;
    color: gray;
    text-decoration: none !important;
    overflow-y: hidden;
  }

  .introinput1:focus {
    text-decoration: none !important;
  }

  .introinput1::placeholder {
    color: gray;
  }

  .intro {
    position: absolute;
    top: 32%;
    left: 40%;
    font-style: italic;
  }

  .bothintros > hr {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 30%;
    background-color: lightblue;
    border: none;
  }

  .info > hr {
    width: 100px;
    height: 1px;
    background-color: lightblue;
    border: none;
  }

  .rankd {
    padding: 10px;
    width: 70px;
  }

  .rankn {
    margin-top: -46px;
    margin-left: 48px;
    color: lightskyblue;
  }

  .Profile2 {
    margin-top: 5px;
    width: 40px;
  }

  .camera {
    width: 40px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .middle {
    margin-top: 30px;
    height: 2px;
    background-color: rgb(2, 59, 78);
    border: none;
  }

  .ClickOption {
    display: flex;
  }

  .changepic {
    display: none;
  }
}
