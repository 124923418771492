.Pledgepic {
  width: 170px;
  height: 170px;
  border-radius: 20px;
}

.picline {
  display: flex;
  justify-content: center;
}

.allplans {
  display: flex;
  justify-content: center;
}

@keyframes flash {
  from {
    background: linear-gradient(#4651eb, #57e985);
  }
  to {
    background: linear-gradient(#d65c5c, #f54af5);
  }
}
