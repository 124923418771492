.Rankimage {
  width: 50px;
  border-radius: 50%;
  align-items: center;
}

.Rankup {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}

.Rankup > button {
  font-size: 14px;
  font-weight: 100;
  color: aliceblue;
  border: none;
  border-radius: 30px;
  background: linear-gradient(#5ebee7, #59ecf1);
}

.nameletter {
  margin: auto;
  text-align: left;
  width: 100px;
  font-size: 14px;
}

.allrankcolumn {
  display: flex;
  padding-bottom: 4px;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.rankcolumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Border {
  border: 1px solid black;
  border-radius: 50px;
  margin-bottom: 10px;
}
