.filecontainer {
  overflow-x: auto;
  /*overflow: hidden;*/
  width: 100%;
  display: flex;
  /*position: fixed;
  /*position: relative;*/
  /*top: 34%;*/
  /*height: 100%;*/
}

.tempfile {
  border-radius: 20px;
  display: block;
  margin-left: 4px;
}

.videoduration1 {
  position: relative;
  bottom: 35px;
  left: 15px;
  color: aqua;
  font-size: 12px;
}

/*.videoduration1 {
  position: relative;
  bottom: 23px;
  left: 10px;
  color: aqua;
  font-size: 12px;
}*/
