.Message {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  border: 1px solid limegreen;
  font-size: 16px;
  margin-top: 40px;
}
.Message::placeholder {
  font-size: 10px;
  text-align: center;
  padding-top: 5px;
}

.Message:focus {
  border: 1px solid rgb(125, 50, 205);
  outline: none;
}
.messagedrop {
  width: 25px;
  position: absolute;
  right: 4px;

  background: linear-gradient(#90ea08, #74b1cb);
  border-radius: 16px;
}

.messageview {
  margin-top: 597px;
  margin-bottom: 36px;
  width: 100%;
}

.messagestyle {
  display: flex;
  margin-right: 15px;
  border-radius: 15px;
  flex-direction: row-reverse;
  margin-top: 40px;
  overflow-wrap: break-word;
}

.messagestyle1 {
  margin-left: 6px;
  margin-top: 40px;
  padding: 6px 12px;
  background: linear-gradient(#d959f9, #46b8e9);
  border-radius: 15px;
  display: inline-block;
  max-width: 40%;
  overflow-wrap: break-word;
}

.innermessage {
  background: linear-gradient(#59cef9, #46e96f);
  max-width: 40%;
  padding: 6px 12px;
  border-radius: 15px;
}
