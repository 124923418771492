.Banner1 {
  width: 100%;
  height: 50px;
}

.bordercolorchange {
  animation: changeborders 2s linear;
}

@keyframes changeborders {
  0% {
    border: solid 2px white;
  }
  100% {
    border-left: solid 2px green;
  }
}
