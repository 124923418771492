.allrecieve {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
}

.banner1 {
  width: 100%;
  height: 55px;
}

.drops2 {
  border-bottom: 1px black solid;
  /* margin-bottom: 3px;*/
  margin-top: 3px;
  padding-bottom: 3px;
  display: flex;
}

/*.man:last-child {
  margin-bottom: "";
}*/

.current4 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px rgb(84, 188, 226) solid;
  z-index: 1;
}

.alldrops1 {
  position: absolute;
  width: 80%;
  height: 9.2%;

  right: 1.5%;
}

.Noline1 {
  text-decoration: none;
  color: black;
  width: 30%;
}

.messageline {
  display: flex;
  flex-direction: column;
  width: 82%;
  overflow-x: hidden;
}

.messagename {
  width: 100%;
  margin-left: 5px;
  margin-top: 5px;
  display: flex;
}

.messagetext {
  width: 100%;
  margin-left: 5px;
  margin-top: 2px;
  font-style: italic;
  font-size: 14px;
  font-weight: 100;
}

.newmessage {
  position: absolute;
  right: 2px;
}
