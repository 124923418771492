.cashpage {
  background: linear-gradient(#ce8cce, #42b1e0);
}

.cashposition {
  display: flex;
  justify-content: center;
}

.cash1 {
  width: 300px;
}

.cashline > hr {
  border: none;
  background: linear-gradient(#3f369d, #0b9cda);
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cashstatus {
  display: flex;
  text-align: center;
  margin-left: 5px;
}

.cashstatus_text {
  color: azure;
}

.cashcolon {
  margin-left: 10px;
  color: darkblue;
}

.cashnumber {
  margin-left: 10px;
  margin-top: 1px;
  color: aqua;
}
