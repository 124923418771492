.Bottom {
  /*margin-top: -7.6px;*/
  /*margin-top: -6.5px;*/
  width: 100vw;
}

/*@media screen and (max-width: 281px) {
  .Bottom {
    width: 1500px;
    height: 500px;
  }
}

@media screen and (min-width: 282px) and (max-width: 376px) {
  .Bottom {
    width: 100%;
  }
}

@media screen and (min-width: 377px) and (max-width: 393px) {
  .Bottom {
    width: 100%;
  }
}

@media screen and (min-width: 394px) and (max-width: 416px) {
  .Bottom {
    width: 100vw;
  }
}

@media screen and (min-width: 417px) and (max-width: 541px) {
  .Bottom {
    width: 100vw;
  }
}

@media screen and (min-width: 542px) and (max-width: 769px) {
  .Bottom {
    width: 1000px;
    height: 900px;
    margin-left: -300px;
  }
}

@media screen and (min-width: 767px) and (max-width: 818px) {
  .Bottom {
    width: 1500px;
    height: 600px;
    margin-left: -360px;
  }
}

@media screen and (min-width: 819px) and (max-width: 821px) {
  .Bottom {
    width: 1500px;
    height: 600px;
    margin-left: -360px;
  }
}

@media screen and (min-width: 822px) and (max-width: 913px) {
  .Bottom {
    width: 1500px;
    height: 600px;
    margin-left: -260px;
  }
}
*/

@keyframes neoneffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
