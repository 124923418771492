.subscribetitle {
  text-align: center;
  font-size: 30px;
  color: gold;
  font-weight: 200;
}

.subscribeprice {
  text-align: center;
}

.yo {
  background: linear-gradient(#d946e4, #83d7ec);
}
