.blockbox {
  background: linear-gradient(#9ec7f0, #0ac3e8);
  font-size: 10px;
  height: 60px;
  width: 60%;
  padding: 5px;
  border-radius: 10px;
  margin-left: 22%;
}

.blockboxbutton {
  margin-top: 2%;
  /* margin-bottom: 1%;*/
  background: linear-gradient(#2c91d5, #867af0);
  border: none;
  border-radius: 3px;
  color: antiquewhite;
  z-index: 1;
  width: 40%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}
