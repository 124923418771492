.Title_Content {
  /*display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  height: 72px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  width: 100%;
  position: absolute;

  top: 12px;
  /*margin-top: 14px;*/
}

.Title__Help {
  width: 16px;
  height: 46px;
  margin-left: 10px;
  z-index: 1;
  cursor: pointer;
  transition: 1s;
}

.Title__Help3 {
  width: 24px;
  height: 46px;

  padding-left: 10px;
  z-index: 1;
  cursor: pointer;
  visibility: hidden;
}

.helpside {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  animation: move 0.7s ease-out;
}

.toggle {
  width: 20px;
  height: 30px;

  transition: 1s;
  margin-left: 10px;

  cursor: pointer;
}

.CM {
  padding-bottom: 10px;
}

@keyframes move {
  from {
    transform: translate(-10px);
  }
  to {
    transform: translate(0);
  }
}

.Logo {
  height: 20px;
  z-index: 1;
  position: absolute;
  top: 27px;
  right: 50%;
}

.Right {
  display: flex;
  align-items: center;

  z-index: 1;
}

.Line {
  height: 70px;
  width: 2px;
  padding-right: 10px;
  z-index: 1;
}

.Sign-up {
  font-size: 13px;
  z-index: 1;
  color: white;
}

.Signupdash {
  color: turquoise;
  z-index: 1;
  margin-bottom: 4px;
  margin-right: 3px;
}

.Flag {
  width: 35px;
  margin-right: 8px;
  z-index: 1;
}

.Banner {
  position: absolute;
  top: -1px;
  height: 70px;
  width: 100%;
}

.toggle1 {
  width: 20px;
  height: 30px;
  transition: 1s;
  margin-left: 2px;
  cursor: pointer;
  z-index: 1;
}

.LogPanel {
  background: linear-gradient(#489dec, #104d67);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1%;
  top: 68px;
  border-radius: 7%;
  z-index: 1;
}

.LogPanel > input {
  border: none;
  border-bottom: 2px rgb(207, 207, 218) solid;
  border-radius: 3px;
  margin-top: 8px;
  background-color: transparent;
  background: linear-gradient(#55a4ed, #d9eaf1);
  text-align: center;
  height: 25px;
}

.LogPanel > input:focus {
  outline: none;
}

.Loginbutton {
  border: none;
  border-radius: 5px;
  background: linear-gradient(#dea4de, #867af0);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 22px;
  width: 88%;
  cursor: pointer;
  color: white;
}

.Title__Help2 {
  width: 11px;
  cursor: pointer;
  z-index: 1;
}

.Send4 {
  color: rgb(70, 119, 162);
  border: none;
  border-bottom: 2px rgb(24, 84, 195) solid;
  border-radius: 4px;

  margin-top: 10px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  height: 30px;
  text-align: center;
  animation: glacier 1s infinite linear;
}

.Send4:focus {
  outline: 0;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: none;
}

.Send5 {
  color: rgb(70, 119, 162);
  border: none;
  border-bottom: 2px rgb(24, 84, 195) solid;
  border-radius: 4px;
  padding-left: 10px;
  margin-top: 10px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  height: 30px;
  text-align: center;
  animation: glacier 1s infinite linear;
}

.Send5:focus {
  outline: 0;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: none;
}

.reset {
  background: linear-gradient(#489dec, #104d67);
  color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1%;
  top: 68px;
  width: 200px;
  border-radius: 7%;
  z-index: 1;
}

.reset > .reset_text {
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

.code2 {
  width: 100px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(#1adf55, #31b8f2);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 22px;
  align-self: center;
  cursor: pointer;
  color: white;
}

.phone1 {
  height: 150px;
  width: 150px;
  padding-bottom: 10px;

  align-self: center;
}

.current5 {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  border: 2px rgb(84, 188, 226) solid;
  cursor: pointer;
}

@media screen and (max-width: 281px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 282px) and (max-width: 376px) {
  .Title__Help1 {
    width: 24px;
    height: 30px;
    margin-left: 10px;
    z-index: 1;
    cursor: pointer;
    transition: 1s;
  }

  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }

  .toggle {
    width: 20px;
    height: 30px;

    transition: 1s;

    cursor: pointer;
  }
}

@media screen and (min-width: 377px) and (max-width: 393px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 394px) and (max-width: 416px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 417px) and (max-width: 541px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 542px) and (max-width: 769px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 767px) and (max-width: 818px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}

@media screen and (min-width: 819px) and (max-width: 821px) {
  .Logo {
    width: 15px;
  }

  .Line {
    opacity: 0;
  }
}
