.ProgressbarGrey {
  width: 80%;
  height: 2px;
  background: rgb(240, 226, 226);
  border-radius: 5px;
}

.ProgressbarColored {
  height: 2px;
  background: #00eeff;
  border-radius: 5px;
  transition: 50ms;
}
