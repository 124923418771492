.SettingDesign {
  background: linear-gradient(#ce8cce, #42b1e0);
  height: 100vh;
}

.UserOpTop {
  display: flex;
  justify-content: center;
}

.UserOp1 {
  width: 30%;
}

.Privacy {
  display: flex;
  justify-content: space-between;
}

.Profile4 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px blueviolet solid;
  background: linear-gradient(#121112, #42b1e0);
  margin-left: 5px;
}

.PrivacyOptions {
  margin-right: 5px;
}

.arrow {
  width: 100px;
  margin-left: 10px;
}

.Locked {
  width: 80px;
  height: 80px;
}

.Locked1 {
  width: 80px;
  height: 80px;
  background: linear-gradient(#d42020, #ec59f1);
  border-radius: 50%;
  margin-right: 5px;
}

.Planet1 {
  width: 65px;
  height: 80px;
  background: linear-gradient(#5ebee7, #59ecf1);
  border-radius: 10px;
}

.Planet2 {
  width: 65px;
  height: 80px;
}

.MessageRestrictions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MessageIcon {
  width: 65px;
  height: 70px;
  margin-left: 7px;
}

.arrow1 {
  width: 60px;
}

.Planet3 {
  width: 45px;
  height: 60px;
  background: linear-gradient(#5ebee7, #59ecf1);
  border-radius: 10px;
}

.Planet4 {
  width: 45px;
  height: 60px;
}

.Profile5 {
  margin-top: 5px;
  width: 40px;
  background: linear-gradient(#5ebee7, #59ecf1);
  border-radius: 30%;
  padding: 5px;
}

.pledge1 {
  width: 30px;

  margin-left: 5px;
}

.pledge2 {
  width: 30px;
  background: linear-gradient(#5ebee7, #59ecf1);
  border-radius: 10px;
  margin-left: 5px;
}

.rankd1 {
  width: 70px;
}

.rankd2 {
  width: 70px;
  margin-right: 5px;
  background: linear-gradient(#5ebee7, #59ecf1);
  border-radius: 20px;
}

.rankn3 {
  position: absolute;
  right: 6.3%;
  top: 50.6%;
  color: rgb(231, 213, 51);
}

.rankn2 {
  color: rgb(241, 150, 231);
  position: absolute;
  right: 4.7%;
  top: 50.6%;
}

.rankn4 {
  color: rgb(89, 26, 235);
  position: absolute;
  right: 4.1%;
  top: 51.1%;
  font-size: 13px;
}

.rankn5 {
  color: rgb(147, 12, 16);
  position: absolute;
  right: 4.5%;
  top: 51.7%;
  font-size: 9px;
}

.rankn6 {
  color: rgb(241, 150, 231);
  position: absolute;
  right: 4.7%;
  top: 63.9%;
}

.rankn7 {
  position: absolute;
  right: 6.3%;
  top: 63.9%;
  color: rgb(231, 213, 51);
}

.rankn8 {
  color: rgb(89, 26, 235);
  position: absolute;
  right: 4.1%;
  top: 64.2%;
  font-size: 13px;
}

.rankn9 {
  color: rgb(147, 12, 16);
  position: absolute;
  right: 4.1%;
  top: 64.8%;
  font-size: 9px;
}

.uparrow {
  width: 10px;
  flex: 0.5;
  margin-right: 3px;
}

.rankarrow {
  display: flex;
  align-items: flex-start;
}

.Blockrank {
  width: 100%;
  height: 25%;

  position: absolute;
  top: 58%;
}

.Blockrank1 {
  width: 100%;
  height: 25%;
  position: absolute;
  top: 71%;
}

.blockinsert {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blockrank > .blockinsert > input {
  width: 40%;
  height: 20px;
  line-height: 20px;
  border: none;
  border-bottom: 10px;
  margin-bottom: 5px;
  background: linear-gradient(#f65e5e, #a046e9);
  text-align: center;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: glacier 1s infinite linear;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.Blockrank1 > .blockinsert > input {
  width: 40%;
  height: 20px;
  line-height: 20px;
  border: none;
  border-bottom: 10px;
  margin-bottom: 5px;
  background: linear-gradient(#f65e5e, #a046e9);
  text-align: center;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: glacier 1s infinite linear;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.Blockrank > .blockinsert > input::placeholder {
  color: #59ecf1;
  transform: translateY(-2px);
  font-size: 8px;
}

.Blockrank1 > .blockinsert > input::placeholder {
  color: #59ecf1;
  transform: translateY(-2px);
  font-size: 8px;
}

.Blockrank > .blockinsert > input:focus {
  outline: none;
  animation: none;
}

.Blockrank1 > .blockinsert > input:focus {
  outline: none;
  animation: none;
}

.blockoption {
  display: flex;
  justify-content: center;
}

.blockoption > button {
  width: 100px;
  height: 21px;
  border: none;

  font-size: 10px;
  border-radius: 30px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.blockoption > button:first-child {
  background: linear-gradient(#5ee7f6, #a046e9);
  margin-right: 10px;
  color: #59ecf1;
}

.blockoption > button:nth-child(2) {
  background: linear-gradient(#f65e5e, #7de8bd);
  color: white;
}

.CommentRestrictions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commenttap1 {
  margin-left: 11px;
}

.questionsin {
  text-align: center;
}
