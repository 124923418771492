/*.Long {
  height: 100vh;
  width: 100vh;
}*/

.Wide {
  width: 100%;

  /*needs to be able to turn sideways and the style changes to fit full screen like youttube video */
}

.fullscreencontrol1 {
  position: absolute;
  top: 32.4%;
  width: 45%;
  height: 223px;
  z-index: 1;
}

.controlline > * {
  margin-right: 5px;
}
