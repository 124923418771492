* {
  margin: 0;
}

textarea {
  border-radius: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}
