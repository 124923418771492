.month {
  background: linear-gradient(#c4d1d2, #42b1e0);
  border-radius: 8px;
}

.Pledgepic {
  margin-top: 20px;
}

.pledgeoptions {
  position: relative;
  top: 100;
  right: 0;
}

.pricepoint,
.durationline > input,
.priceline input {
  width: 40px;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  border-bottom: 1px black solid;
  border-radius: 0;
}

.priceduration {
  text-align: center;
  font-size: 10px;
  margin-top: 3px;
}

.subscribeline {
  display: flex;
  justify-content: center;
  align-content: end;
  margin-bottom: 3px;
  margin-top: 2px;
}
.priceline {
  margin-bottom: 5px;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleline {
  color: #578ce9;
  font-size: 13px;
}

.priceletter {
  color: aquamarine;
  font-size: 14px;
}

.durationline {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: blueviolet;
}

.plansetting {
  border: 1px solid black;
  margin-top: 18px;
}
.pricepoint {
  margin-left: 7px;
}

.Premiumoption {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 40px;
}

.premium {
  border: 1px solid turquoise;
  border-radius: 20px;
  background: linear-gradient(#ced2c4, #42b1e0);
  width: 30%;
  font-size: 12px;
  color: rgb(171, 129, 189);
}

.itemdisplay {
  background: linear-gradient(#ced2c4, #42b1e0);
  border-radius: 40px;
  padding: 1px 5px;
}

.allplans1 {
  display: flex;

  border-bottom: 1px solid black;
  padding-bottom: 15px;
  overflow-x: auto;
  width: 100%;

  height: 20%;
}

.premiumplans {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.premiumplans1 {
  border: 1px solid;
  border-radius: 10px;
  background: linear-gradient(#d046eb, #578ce9);
  width: 120px;
}

.details {
  display: flex;
  justify-content: space-between;
}

.details1:lang(ja) {
  font-size: 9px;
  color: gold;
  margin-top: 7px;
}

.agreeline {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contract {
  color: #578ce9;
  height: 10px;
  font-size: 14px;
}

.Contractread {
  display: flex;
  justify-content: center;
}

.Pselect1 {
  display: flex;
  justify-content: center;
}
.Pselect1 > button {
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 10px;

  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}

.Pselect1 > button:first-child {
  background: linear-gradient(#5ee7f6, #a046e9);
  color: #59ecf1;
}

.Pselect1 > button:nth-child(2) {
  background: linear-gradient(#f65e5e, #7de8bd);
  color: white;
  margin-left: 5px;
}

.VideoSlide {
  position: relative;
  min-height: 20px;
}

.VideoSlide > input[type="range"] {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: none;
  appearance: none;
}

.VideoSlide > input[type="range"] {
  position: absolute;
  width: 100%;
  height: 1px;
}

.VideoSlide > input[type="range"]:nth-child(1) {
  background-color: transparent;
  appearance: none;
}

.VideoSlide > input[type="range"]:nth-child(2) {
  background-color: black;
  appearance: none;
}

.uploadbutton {
  /*position: relative;*/
  /*bottom: 35px;*/
  /* left: 295px;*/
  background: linear-gradient(#dea4de, #86c9f2);
  border: none;
  border-radius: 5px;
  height: 20px;
  width: 100%;
  cursor: pointer;
  color: white;
  padding: 2px;
  font-size: 13px;
  margin-bottom: 6px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}
