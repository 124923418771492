.drop {
  width: 100%;
  height: 100vh;
  background: linear-gradient(#a9baf1, #d9eaf1);
}

.Planet {
  width: 50px;
}

.imagecontainer {
  overflow-x: auto;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 34%;
}

.commentimagecontainer {
  overflow-x: auto;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  position: "relative";
}

.dropfile {
  width: 200px;
  height: 200px;
  border-radius: 20px;
}

.dropimage1 {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin-left: 8px;
}

.dropimage2 {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin-left: 8px;
}

.dropimage3 {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin-left: 8px;
}

.dropb {
  width: 50px;
}

.dropb1 {
  width: 35px;
}

.current {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px rgb(84, 188, 226) solid;
}

.topline {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  top: 0;
  width: 100%;
  height: 56px;
}

.current1 {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  border: 2px rgb(84, 188, 226) solid;
}

.current3 {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  border: 2px rgb(84, 188, 226) solid;
  background: linear-gradient(#121112, #42b1e0);
}

.drops {
  border: 0.5px rgb(81, 178, 211) solid;
  height: 200px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  position: relative;
}

.postlinetop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3px;
  margin-left: 3px;
}

.postoption {
  width: 15px;
}

.postphotoline {
  display: flex;
  justify-content: center;
  align-items: last baseline;
  flex-wrap: wrap;
  z-index: 1;
}

.postphoto {
  width: 205px;
  height: 205px;
  border-radius: 20px;
  margin-left: 20px;
  z-index: 1;
}

.Delete {
  /*
  position: absolute;
  top: 0;
  right: 5px;
  */
  z-index: 1;
  margin-right: 2px;
  margin-top: -5px;
  /*padding-bottom: 7px;*/
}

.Delete > button {
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 10px;
  font-size: 8px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.Delete > button:first-child {
  background: linear-gradient(#5ee7f6, #a046e9);
  color: #59ecf1;
}

.Delete > button:nth-child(2) {
  background: linear-gradient(#ed457d, #8056ec);
  color: white;
  margin-left: 5px;
}

.textline {
  display: flex;

  margin-top: 3px;
}

.stylerank {
  margin-top: 2px;
  /* margin-left: 14.5px;*/
  color: aqua;
  font-size: 14px;
  z-index: 1;
}

.text,
.text1 {
  font-size: 12px;
}

.text1 {
  max-width: 60%;
}

.text {
  text-align: center;
  flex: 0.82;
  max-width: 70%;
  word-wrap: break-word;
}

.alldrops {
  position: absolute;
  width: 70%;
  height: 83%;
  top: 0%;
  left: 13.5%;
  border-left: 1px solid black;
}

.date {
  font-size: 8px;
  position: absolute;
  bottom: 36px;
  right: 1px;
  display: flex;
  align-items: center;
}

.Timesplit {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 1px;
  width: 2px;
}

.dropline {
  margin-top: 20px;
}

.dropoption {
  display: flex;
  justify-content: space-between;
  position: absolute;
  margin-bottom: 2px;
  padding-top: 2px;
  border-top: 1px solid rgb(10, 60, 60);
  bottom: 0;
  width: 100%;
}

.commentgroup {
  display: flex;
  align-items: start;
  flex: 0.5;
  margin-left: 3px;
}

.commentcount {
  width: 10px;
  font-size: 12px;
}

.dropselect {
  position: absolute;
  bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.commenttap {
  width: 30px;
  margin-left: 5px;
}

.Redrop {
  width: 25px;
}

.redropline {
  display: flex;
  flex: 0.5;
  margin-right: 13px;
}

.upload {
  display: none;
}

/*
.redropstyle{
  bottom:
}

.redropstyle > button:first-child {
  position: absolute;
  right: 50%;
  bottom: 5%;
}

.redropstyle > button:nth-child(2) {
  position: absolute;
  right: 23%;
  bottom: 5%;
}
  */

.Rankmarked {
  position: absolute;
  top: 51px;
  /* top: 25.6%;*/
  left: 1.4%;
}

:fullscreen {
  width: 100%;
  height: 200px !important;
  background: linear-gradient(#2e57dd, #d9eaf1);
}

.fullscreencontrol {
  position: absolute;
  top: 38%;
  width: 45%;
  height: 25%;
  z-index: 1;
}

.videoduration {
  position: relative;
  bottom: 40px;
  left: 20px;
  color: aqua;
  font-size: 12px;
}

.ProgressbarPosition {
  position: relative;
  bottom: 250px;
}

.Blockposition {
  position: absolute;
  bottom: 40%;
  width: 100%;
}

.CommentCameraline {
  position: relative;
  right: 3px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Commentsendbutton {
  /*position: relative;*/
  /*bottom: 35px;*/
  /* left: 295px;*/
  background: linear-gradient(#dea4de, #86c9f2);
  border: none;
  border-radius: 5px;
  height: 20%;
  width: 20%;
  cursor: pointer;
  color: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}
