.Pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /*height: 102vh;*/
  height: 125vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: linear-gradient(#c21adf, #31b8f2);
}

.ewelcome {
  font-size: 38px;
  border-bottom: 1px dotted white;
  border-top: 1px dotted white;
  margin-top: 21px;
  color: rgb(54, 174, 217);
  /*white-space: nowrap;*/
}

.dots {
  position: absolute;
  top: 120px;
  right: 310px;
}

.Profile1 {
  width: 180px;
  margin-top: 40px;
  border: 1px solid white;
  border-radius: 50%;
  background: linear-gradient(#121112, #42b1e0);
}

.phone {
  margin-top: 12px;
  /*margin-top: 30px;*/
  width: 200px;
  height: 200px;
}

.code {
  width: 100px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(#1adf55, #31b8f2);
  margin-top: 10px;
  margin-left: 30px;
  /*maybe margin bottom here?*/
  margin-bottom: 10px;
  height: 22px;
  align-self: center;
  cursor: pointer;
  color: white;
  display: flex;
  padding-left: 36px;
}

.code1 {
  width: 100px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(#1adf55, #31b8f2);
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: 10px;
  height: 22px;
  align-self: center;
  cursor: pointer;
  color: white;
  display: flex;
  padding-left: 17px;
}

.Send {
  color: rgb(70, 119, 162);
  border: none;
  border-bottom: 2px rgb(24, 84, 195) solid;
  border-radius: 4px;
  margin-top: 10px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  height: 30px;
  text-align: center;
  animation: glacier 1s infinite linear;
}

.Send1 {
  color: rgb(70, 119, 162);
  border: none;
  border-bottom: 2px rgb(24, 84, 195) solid;
  border-radius: 4px;
  padding-left: 20px;
  margin-top: 10px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  height: 30px;
  text-align: center;
  animation: glacier 1s infinite linear;
}

@keyframes glacier {
  from {
    border-bottom: 2px rgb(99, 116, 148) solid;
  }
  to {
    border-bottom: 2px rgb(212, 234, 209) solid;
  }
}

::placeholder {
  color: white;
}

.Send:focus {
  outline: 0;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: none;
}

.Send1:focus {
  outline: 0;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: none;
}

.user_register {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  color: white;
  border-top: 1px solid white;
}

.Send3 {
  color: rgb(70, 119, 162);
  border: none;
  border-bottom: 2px rgb(24, 84, 195) solid;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: linear-gradient(#a9baf1, #d9eaf1);
  height: 30px;
  text-align: center;
  animation: glacier 1s infinite linear;
}

.Send3:focus {
  outline: 0;
  border-bottom: 2px rgb(93, 123, 179) solid;
  animation: none;
}

.codesend {
  width: 100px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(#1adf55, #31b8f2);
  margin-top: 10px;

  margin-bottom: 10px;
  height: 22px;
  align-self: center;
  cursor: pointer;
  color: white;
}

@media screen and (max-height: 667px) {
  /*THIS IS CORRECT DONT DELETE THIS! THIS IS NEEDED FOR THE HEIGHT BEING SMALL */
  /*THIS IS NOT THE AMATEUR MISTAKE*/
  .Pop {
    height: 150vh;
  }
}

@media screen and (max-width: 281px) {
  .dots {
    position: absolute;
    top: 38px;
    right: 5px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 282px) and (max-width: 376px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 5px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 70px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 377px) and (max-width: 393px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 15px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 394px) and (max-width: 416px) {
  .dots {
    position: absolute;
    top: 60px;
    right: 25px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 417px) and (max-width: 541px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 7px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}
/*
@media screen and (min-width: 542px) and (max-width: 769px) {
  .Title__Help {
    width: 18px;
  }

  .Logo {
    width: 15px;
    margin-right: 80px;
  }

  .Line {
    opacity: 0;
  }

  .Sign-up {
    width: 30px;
    height: 30px;
    margin-right: -5px;
  }

  .Login {
    width: 45px;
    height: 45px;
    margin-right: -5px;
  }

  .Flag {
    width: 35px;
    height: 30px;
    margin-right: 200px;
  }
}
  */

@media screen and (min-width: 767px) and (max-width: 818px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 819px) and (max-width: 821px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 70px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 822px) and (max-width: 913px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 914px) and (max-width: 1025px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}

@media screen and (min-width: 1026px) and (max-width: 1281px) {
  .dots {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 25px;
  }

  .Profile1 {
    width: 200px;
    margin-top: 40px;
    border: 1px solid white;
    border-radius: 50%;
    background: linear-gradient(#121112, #42b1e0);
  }
}
