.Panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  position: fixed;
  /*bottom: -0.3px;*/
  border-top: 0.1px solid blueviolet;
  bottom: 0vh;
  padding-top: 2px;
  padding-bottom: 2px;
  /*maybe have animation to change linear gradient back and fourth*/
  /*background: linear-gradient(#80caed, #d9eaf1);*/
  background: linear-gradient(#8099ed, #d9eaf1);
  z-index: 1;
  /*make it higher if need be*/

  /*animation: 3s backgroundswitch linear infinite;*/
}

@keyframes backgroundswitch {
  0% {
    background: linear-gradient(#80caed, #d9eaf1);
  }

  100% {
    background: linear-gradient(#8099ed, #d9eaf1);
  }
}

.Notifyline {
  display: flex;
  align-items: flex-start;
}

.Messageline {
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
  /* background: linear-gradient(#f0e1f3, #beecb6, #4cddfa);*/
  /* background-image: conic-gradient(
    rgb(0, 221, 255),
    rgb(244, 42, 237),
    rgb(115, 242, 170),
    rgba(170, 119, 229, 0.495),
    rgb(0, 221, 255)
  );
  border-radius: 20%;*/
}
.notificationnumber {
  font-size: 10px;
  width: 10px;

  margin-top: 3px;
}
