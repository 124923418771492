.back {
  position: absolute;
  top: -10px;
  left: 0px;
  width: 40px;
  height: 50px;
}

.IntroLine2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.Noline {
  text-decoration: none;
}

.interact {
  width: 30px;
}

.aboutme {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: 200;
}
