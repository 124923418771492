.LoadingAnimation {
  position: relative;
  background: linear-gradient(#f9a2f5, #6cc9f0, rgb(130, 242, 130));
  width: 75px;
  height: 30px;
  border-radius: 60%;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.LoadingAnimation::after,
.LoadingAnimation::before {
  /* --angle: 0deg;*/
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;

  /* background-image: conic-gradient(
    transparent -120%,
    rgb(0, 221, 255),
    rgb(244, 42, 207),
    rgb(0, 221, 255)
  );*/

  background-image: conic-gradient(
    from var(--angle),
    transparent 60%,
    rgb(0, 221, 255),
    rgb(244, 42, 207),
    rgb(244, 42, 207),
    rgb(0, 221, 255)
  );
  padding: 3px;
  z-index: -1;
  transform: translate(-50%, -50%);
  border-color: transparent;
  border-radius: inherit;
  /*animation: timerborder var(--d) linear*/
  animation: 3s spinborder linear infinite;
}

/*add filter and opacity once we figure out how to remove the weird 
white border */
/*.LoadingAnimation::before {
  filter: blur(0.5rem);
  opacity: 0.8;
}*/

/*.LoadingAnimation::before,
.LoadingAnimation::after {
  filter: blur(0.5rem);
  opacity: 0.8;
}*/

@keyframes spinborder {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

@keyframes timerborder {
  0% {
    background-image: conic-gradient(
      transparent 120%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  15% {
    background-image: conic-gradient(
      transparent 80%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  25% {
    background-image: conic-gradient(
      transparent 45%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  40% {
    background-image: conic-gradient(
      transparent 22%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  50% {
    background-image: conic-gradient(
      transparent -10%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  60% {
    background-image: conic-gradient(
      transparent -35%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  75% {
    background-image: conic-gradient(
      transparent -65%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  85% {
    background-image: conic-gradient(
      transparent -90%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
  100% {
    background-image: conic-gradient(
      transparent -120%,
      rgb(0, 221, 255),
      rgb(244, 42, 207),
      rgb(0, 221, 255)
    );
  }
}
